.note-container {
  position: relative;
  flex-direction: column;
  border-radius: 4px;
  background-color: var(--white);
  overflow: hidden;

  height: 320px;
  min-width: 320px;
  // margin: 12px;
  background-color: var(--light-gray);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);

  .delete-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 8px;
    top: 8px;
    opacity: 0.75;
    width: 32px;
    height: 32px;
    z-index: 100;
    visibility: hidden;
    color: var(--dark-gray);
    &.visible {
      visibility: visible;
      cursor: pointer;
    }
  }

  .text-area-container {
    background-color: var(--white);
    .text-area {
      box-shadow: none;
      border-bottom: none;
    }

    .info-row-container {
      display: flex;
      align-items: center;
      height: 40px;
      .character-counter {
        margin-right: 8px;
      }
    }
  }
}
