html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  overscroll-behavior-block: autocontain;

  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}
