html {
  --sidebar-width: 276px;

  --content-height: 100vh;
  --content-width: calc(100vw - var(--sidebar-width));
  --content-padding: 36px;
}

* {
  box-sizing: border-box;
}
