.reset-password-success {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--background);

  .message {
    max-width: 70vw;
    margin-bottom: 56px;
    color: var(--main-dark);
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 1px;
  }
}
