.horse-details-container {
  height: 100%;
  width: var(--content-width);
  background-color: var(--background);
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 44px;
  overflow-y: scroll;

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .name-contact-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;

      .name {
        font-size: 32px;
        display: flex;
        align-items: center;

        .arrow {
          margin: 0px 12px;
        }
      }
    }

    .stats {
      display: flex;
      flex-direction: row;
      width: 100%;

      .appointments {
        padding: 24px 24px 24px 0px;
      }

      .action-text {
        flex: 1;
        align-self: center;
        text-align: right;
        font-size: 20px;
        margin-right: 12px;
        color: var(--secondary-color);
        cursor: pointer;
      }
    }
  }

  .note-row-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, 320px);
    gap: 24px;
  }

  .image {
    height: 320px;
    width: 320px;
  }

  // .content-container {
  //   width: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   margin-top: 16px;

  //   .images-header-row {
  //     display: flex;
  //     flex-direction: row;
  //     width: 100%;
  //     padding-bottom: 12px;
  //     align-items: baseline;
  //     justify-content: space-between;

  //     .images-header {
  //       font-size: 24px;
  //     }

  //     .action {
  //       font-size: 20px;
  //       color: var(--secondary-color);
  //       cursor: pointer;
  //     }
  //   }

  //   .image {
  //     margin: 12px;
  //     // height: 160px;
  //     // width: 160px;
  //     height: 320px;
  //     width: 320px;
  //   }
  // }
}
