.edit-subscription-modal-container {
  min-height: 350px;
  width: 650px;

  --vertical-padding: 24px;
  --title-height: 24px;
  --bottom-buttons-height: 60px;

  position: relative;
  padding: var(--vertical-padding) 30px;
  display: flex;
  flex-direction: column;
  background: var(--background);
  overflow-y: scroll;

  .setup-title {
    flex-shrink: 0;
    height: var(--title-height);
    width: 100%;
    text-align: center;
    color: var(--main);
    font-size: 18px;
  }

  .content {
    flex: 1;
    width: 100%;
    // height: var(--step-content-height);
    margin-top: var(--step-content-top-margin);
    padding: 0px 30px;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    .header {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-evenly;
      align-items: baseline;
      font-family: Rubik;
    }

    .title-container {
      flex-direction: row;
      display: flex;
      width: 100%;
      justify-content: space-between;

      .title {
        margin-bottom: 32px;
        font-size: 32px;
        font-weight: 500;
      }

      .delete-button {
        border-color: red;
        color: red;
      }
    }

    .error {
      font-size: 16px;
      color: red;
    }

    .section-container {
      &.columns {
        display: flex;
        justify-content: space-between;

        .col {
          flex: 1;
        }
      }

      .input-container {
        margin-bottom: 20px;

        &.text-area {
          // flex: 1;
          // height: 96px;
          font-family: "Rubik";
          margin-bottom: 0px;
        }

        .input-title {
          margin-bottom: 8px;
          font-weight: 500;
          color: var(--darkGray);

          .protation-loader-container {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .message {
        margin: 0px 50px;
        color: var(--gray);
        opacity: 0.5;
      }

      .error {
        color: var(--red);
        opacity: 1;
      }

      .review-col-title {
        margin-bottom: 12px;
        font-size: 20px;
        font-weight: 500;
      }
    }

    .long-running-message {
      margin-top: 16px;
    }
  }

  .bottom-buttons {
    flex-shrink: 0;
    height: 60px;
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-buttons {
      display: flex;

      > :not(:last-child) {
        margin-right: 20px;
      }
    }

    .modal-button {
      height: 40px;
      width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--main);
      color: #fff;
      outline: none;
      border: none;
      border-radius: 2px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      font-weight: 700;
      cursor: pointer;

      &:active {
        opacity: 0.9;
      }

      &.prev {
        margin-left: 24px;
        background-color: #fff;
        color: var(--main);
        border: 2px solid var(--main);
      }

      &.cancel {
        margin-left: 24px;
        background-color: #fff;
        color: var(--darkGray);
        border: 2px solid var(--darkGray);
      }
    }
  }
}
