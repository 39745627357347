@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

html,
body {
  font-family: "Rubik";

  --content-height: 100vh;

  --primary: #09bc8a;
  --primary-color: #09bc8a;

  --red: red;
  --super-light-gray: #f5f5fb;
  --light-gray: #e4e7eb;
  --medium-gray: #c1c1d1;
  --gray: #999;
  --dark-gray: #5a5b5c;
  --black: #000;
  --white: #fff;

  // --secondary-color: #5b0558; //stripe 0074d4
  --secondary-color: #8a09bc;

  --background: hsl(266, 12%, 98%);
  --background-color: hsl(266, 12%, 98%);

  // --background-color: #eff4f9;
  // --background: #eff4f9;

  --stripe-color-danger: #df1b41;
  --stripe-font-weight-normal: 400;
  --stripe-font-size-sm: 0.93rem;
  --stripe-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  --stripe-p-spacing-1: 0.25rem;

  --shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.15);
}
