table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
  border-collapse: collapse;
  font-variant-numeric: tabular-nums;

  th {
    padding: 10px 0 10px 16px;
    color: var(--secondary-color);
    background-color: #f7f6f6;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-transform: uppercase;
    vertical-align: bottom;
    border-bottom: 1px solid #eaeaea;

    &:last-child {
      padding-right: 16px;
      text-align: right;
    }

    &.center {
      padding: 10px 0px;
      text-align: center;
    }
  }

  tr {
    &.selectable-row {
      cursor: pointer;

      &:hover {
        background-color: #f7f6f6;
      }

      &:active {
        opacity: 0.7;
      }
    }
  }

  td {
    border-bottom: 1px solid #eaeaea;
    padding: 30px 0 30px 16px;
    font-weight: 500;
    vertical-align: middle;

    &:last-child {
      padding-right: 16px;
      text-align: right;
    }

    &.center {
      padding: 30px 0;
    }

    .cell-contents {
      height: 100%;

      > * + * {
        margin-top: 4px;
      }

      &.center {
        width: 100%;
        display: inline-flex;
        justify-content: center;
      }
    }

    .action {
      color: var(--secondary-color);
      font-weight: 500;
      cursor: pointer;
      user-select: none;
      justify-content: flex-end;

      + .action {
        margin-top: 4px;
      }

      &:hover {
        opacity: 0.9;
      }

      &:active {
        opacity: 0.7;
      }

      &.disabled {
        opacity: 0.3;
        cursor: initial;
        pointer-events: none;
      }
    }
  }
}
