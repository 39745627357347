.clients-container {
  height: 100%;
  width: var(--content-width);
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 44px;

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .name-contact-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .name {
        font-size: 36px;
        display: flex;
        align-items: center;

        .arrow {
          margin: 0px 12px;
        }
      }
    }

    .stats {
      display: flex;
      flex-direction: row;
      width: 100%;

      .appointments {
        padding: 24px 24px 24px 0px;
      }

      .action-text {
        flex: 1;
        align-self: center;
        text-align: right;
        font-size: 20px;
        margin-right: 12px;
        color: var(--secondary-color);
        cursor: pointer;
      }
    }
  }

  .hover {
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
    &:active {
      opacity: 0.3;
    }
  }
}
