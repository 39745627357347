.profile {
  position: relative;
  height: var(--content-height);
  width: var(--content-width);
  padding: 32px 44px;
  background-color: var(--background);
  overflow-y: scroll;

  &.profile-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 36px;
    }
  }

  .content {
    margin-top: 36px;

    .picture-row {
      display: flex;
      align-items: center;

      .picture {
        height: 180px;
        width: 180px;
        margin-right: 36px;
        border-radius: 50%;
        background-color: var(--light-gray);
        box-shadow: var(--big-shadow);
        object-fit: cover;
        cursor: pointer;

        &:active {
          opacity: 0.9;
        }

        &.placeholder {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--gray);
          box-shadow: var(--shadow);
        }
      }

      .primary-info {
        .name {
          font-size: 36px;
        }

        .error {
          color: var(--red);
          font-size: 18px;
          font-weight: 500;
        }

        .email {
          color: var(--gray);
          font-size: 18px;
        }

        .buttons-row {
          margin-top: 16px;
          display: flex;
          align-items: center;

          :not(:first-child) {
            margin-left: 12px;
          }
        }
      }

      .edit-subscription-button {
        border-color: var(--secondary-color);
        color: var(--secondary-color);
      }
    }

    .section {
      margin-top: 24px;
      display: flex;

      .header {
        height: 55px;
        margin-bottom: 12px;
        padding-top: 32px;
        color: var(--gray);
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
      }

      .info-section {
        flex: 1;

        .info-row {
          width: 100%;
          margin-bottom: 16px;
          display: flex;
          justify-content: center;
          align-items: center;

          .info-title {
            width: 156px;
            margin-right: 12px;
            font-weight: 500;
            text-align: right;
          }

          .info-value {
            flex: 1;
            margin-left: 12px;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--dark-primary);
          }
        }
      }

      .actions-section {
        flex: 1;

        .action-row {
          width: 100%;
          margin-bottom: 16px;
          display: flex;
          justify-content: center;
          align-items: center;

          .action {
            flex: 1;
            margin-left: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--secondary-color);
            font-weight: 600;
            user-select: none;

            > span {
              cursor: pointer;

              &:hover {
                opacity: 0.7;
              }

              &:active {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }
}
