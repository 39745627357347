.sidebar-container {
  height: var(--content-height);
  width: var(--sidebar-width);
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  box-shadow: 0px 0 1px 1px black;
  z-index: 999;
}

.sidebar {
  position: relative;
  z-index: 999;
  height: var(--content-height);
  width: var(--sidebar-width);
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  // background-color: #f7f6f6;
  background-color: var(--white);
  border-right: 1px solid #eaeaea;

  .logo-section {
    width: 100%;
    text-align: center;
    margin-bottom: 24px;
    padding-bottom: 12px;
    // border-bottom: 1px solid var(--light-gray);

    .logo {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .logo-icon {
        height: 42px;
      }

      .logo-text {
        display: flex;
        flex-direction: row;
        font-size: 36px;
        color: var(--dark-gray);

        .plus {
          color: var(--secondary-color);
        }
      }
    }
  }

  .links-section {
    &:not(:last-child) {
      margin-bottom: 40px;
    }

    .links-section-title {
      margin-bottom: 12px;
      color: var(--dark-gray);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.75px;
      text-transform: uppercase;
    }

    .sidebar-link-wrapper {
      display: block;
      text-decoration: none;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  .sidebar-link {
    padding: 12px;
    display: flex;
    align-items: center;
    color: var(--dark-gray);
    letter-spacing: 0.75px;
    font-size: 20px;

    &.active {
      color: var(--white);
      background-color: var(--primary-color);
      border-radius: 3px;
      text-decoration: none;
    }

    &.back {
      color: var(--primary-color);
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        opacity: 0.7;
      }
    }

    .link-icon {
      height: 28px;
      width: 28px;
      margin-right: 16px;
      stroke: var(--dark-gray);
    }
    &.active .link-icon {
      stroke: var(--secondary-color);
    }

    &.back .link-icon {
      stroke: var(--primary-color);
    }
  }

  .flexer {
    flex-grow: 1;
  }

  .spacer {
    height: 8px;
  }

  .settings-button {
    position: relative;
    height: 32px;
    width: 32px;
    margin-left: 13px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 0.7;
    }

    &.active {
      background-color: var(--dark-gray);

      &:hover {
        opacity: 1;
      }

      &:active {
        opacity: 1;
      }

      .icon {
        color: var(--secondary-color);
      }
    }

    .icon {
      height: 24px;
      width: 24px;
      color: var(--dark-gray);
    }
  }
}
