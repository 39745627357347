.auth-wrapper {
  position: relative;
  height: var(--content-height);
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--background);

  > * {
    height: 100%;
  }

  .left-col {
    flex: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color);

    .auth-logo {
      width: 50%;
    }
  }

  .right-col {
    flex: 5;

    .auth-content {
      height: 100%;
      position: relative;
      z-index: 1;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .auth-logo {
        margin: 0px;
        font-size: 26px;
        line-height: 24px;
        font-weight: 700;

        .logo {
          font-size: 56px;
          color: var(--dark-gray);

          .bud {
            color: var(--secondary-color);
          }
        }
      }

      .auth-square {
        height: 340px;
        width: 382px;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

        .auth-logo {
          width: 70%;
        }

        .auth-inputs {
          width: 322px;
          margin-top: 16px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .input + .input {
            margin-top: 28px;
          }
        }

        .auth-button {
          height: 40px;
          width: 322px;
          margin-top: 30px;
          background-color: var(--primary-color);
          color: #fff;
          outline: none;
          border: none;
          border-radius: 2px;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.5px;
          font-weight: 700;
          cursor: pointer;

          &:active {
            opacity: 0.9;
          }
        }

        .input-error + .auth-button {
          margin-top: 8px;
        }

        .options-links {
          display: flex;
          flex-direction: column;
          align-items: center;

          .link {
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
            // opacity: 0.3;
            text-decoration: none;
            color: var(--primary);
            cursor: pointer;
            user-select: none;

            &:active {
              opacity: 0.2;
            }
          }
        }
      }
    }
  }
}
