.dashboard-container {
  height: 100%;
  width: var(--content-width);
  padding-bottom: 0;

  display: grid;
  grid-template-columns: 80% 20%;
  grid-template-rows: 12% auto;
  grid-template-areas:
    "header sidebar"
    "body sidebar";

  .header {
    grid-area: header;
    padding: 32px 44px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .name {
      font-size: 36px;
      display: flex;
      align-items: center;

      .arrow {
        margin: 0px 12px;
      }
    }

    .stats {
      display: flex;
      flex-direction: row;
      width: 100%;

      .appointments {
        padding: 24px 24px 24px 0px;
      }

      .action-text {
        flex: 1;
        align-self: center;
        text-align: right;
        font-size: 20px;
        margin-right: 12px;
        color: var(--secondary-color);
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    background-color: #fefefe;

    grid-area: body;

    .map {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding-top: 12px;

      .map-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .details-container {
          display: flex;
          align-items: center;
          background-color: var(--super-light-gray);
          flex-direction: row;
          padding: 12px;
          margin: 0px 3px;

          .icon {
            margin: 0px 6px;
          }

          .text {
            padding: 6px;
          }
        }
      }

      .map-container {
        flex: 1;
        #map * {
          overflow: visible;
        }
      }

      .stats {
        width: 20%;
        height: 100%;
        background-color: red;
      }
    }
  }

  .upcoming-appointments {
    overflow-y: scroll;
    border-left: 1px solid #eaeaea;
    grid-area: sidebar;

    .appointments-header {
      padding: 12px;
      font-size: 18px;
      text-align: center;
      border-bottom: 1px solid #eaeaea;
    }

    .appointment {
      font-size: 18px;
      padding: 12px;
      border-bottom: 1px solid #eaeaea;
    }
  }
}
