.reset-password {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--background);

  .auth-square-title {
    z-index: 1;
    text-align: center;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .inputs-container {
    height: 472px;
    width: 382px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);

    .auth-inputs {
      width: 322px;
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .auth-input {
      height: 44px;
      width: 70%;
      margin-bottom: 20px;
      padding: 6px 12px;
      border-radius: 6px;
      font-size: 18px;
      // border: 1px solid #aaa;
      border: none;
      outline: none;
      box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);

      &:last-child {
        margin-bottom: 0;
      }

      &::placeholder {
        color: #ccc;
      }
    }

    .sign-up-button {
      height: 40px;
      width: 322px;
      margin: 30px 0px;
      background-color: var(--primary-color);
      color: #fff;
      outline: none;
      border: none;
      border-radius: 2px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
      cursor: pointer;

      &:active {
        opacity: 0.9;
      }

      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }

    .log-in-link-container {
      .log-in-link {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        cursor: pointer;
        color: var(--primary-color);
        text-decoration: none;
        user-select: none;

        &:active {
          opacity: 0.2;
        }
      }
    }
  }
}
