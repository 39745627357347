.client-details-container {
  height: 100%;
  width: var(--content-width);
  background-color: var(--background);
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 44px;
  overflow-y: scroll;

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .name-contact-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .name {
        font-size: 36px;
        display: flex;
        align-items: center;

        .arrow {
          margin: 0px 12px;
        }
      }
    }

    .stats {
      display: flex;
      flex-direction: row;
      width: 100%;

      .appointments {
        padding: 24px 24px 24px 0px;
      }

      .terms-agreement {
        padding: 12px 0px;
        margin-right: 12px;
        text-align: right;
        align-self: center;
      }

      .contact-methods {
        padding: 12px 0px;
        padding-left: 12px;
        text-align: right;
        align-self: center;
        font-size: 20px;
        color: var(--secondary-color);
        cursor: pointer;
      }
    }
  }

  .tables-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .note-row-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, 320px);
    gap: 24px;
  }

  .horse-cards-container {
    width: 100%;
    .title {
      font-size: 24px;
    }
  }

  tbody tr {
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
    &:active {
      opacity: 0.3;
    }
  }

  .horse-cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;

    .card {
      display: flex;
      flex-direction: column;
      border-radius: 16px;
      overflow: hidden;

      height: 280px;
      width: 280px;
      margin: 12px;
      background-color: var(--light-gray);

      &:first-child {
        margin-left: 0px;
      }

      &:hover {
        opacity: 0.9;
        cursor: pointer;
      }

      &:active {
        opacity: 0.7;
      }

      .image-container {
        height: 140px;
        width: 280px;
        position: relative;

        .image {
          position: absolute;
          flex-shrink: 1;
          height: 140px;
          width: 280px;
          background-color: var(--light-gray);
        }
        .no-image {
          position: absolute;
          flex-shrink: 1;
          height: 140px;
          width: 280px;
          z-index: 1;
          background-image: linear-gradient(to top right, var(--secondary-color) 20%, var(--primary-color) 70%);
          //rgba(150, 0, 255, 1)
        }

        .more-images {
          position: absolute;
          height: 140px;
          width: 280px;
          // background-color: var(--light-gray);
          background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.7) 100%);
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          padding: 8px;
          color: var(--white);
          font-size: 11px;
        }

        .no-image-text {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          width: 100%;
          height: 100%;
          z-index: 30;
        }
      }

      .text-container {
        flex: 1;
        padding: 12px;

        .name {
          font-size: 20px;
        }
      }
    }
  }
}
