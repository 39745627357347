.edit-address-modal-container {
  min-height: 450px;
  width: 650px;

  --vertical-padding: 24px;
  --title-height: 24px;
  --bottom-buttons-height: 60px;

  position: relative;
  padding: var(--vertical-padding) 30px;
  display: flex;
  flex-direction: column;
  background: var(--background);
  overflow-y: scroll;

  .setup-title {
    flex-shrink: 0;
    height: var(--title-height);
    width: 100%;
    text-align: center;
    color: var(--main);
    font-size: 18px;
  }

  .content {
    flex: 1;
    width: 100%;
    // height: var(--step-content-height);
    margin-top: var(--step-content-top-margin);
    padding: 0px 30px;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    .header {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-evenly;
      align-items: baseline;
      font-family: Rubik;
    }

    .title-container {
      flex-direction: row;
      display: flex;
      width: 100%;
      justify-content: space-between;

      .title {
        margin-bottom: 32px;
        font-size: 32px;
        font-weight: 500;
      }

      .delete-button {
        border-color: red;
        color: red;
      }
    }

    .error {
      font-size: 16px;
      color: red;
    }

    .section-container {
      &.columns {
        display: flex;
        justify-content: space-between;

        .col {
          flex: 1;
        }
      }

      .input-container {
        margin-bottom: 20px;

        &.multi {
          flex-direction: row;
          display: flex;
          width: 100%;
          justify-content: space-evenly;

          .datetime-picker {
            border: none;
            box-shadow: var(--shadow);
            width: 100%;
            padding: 8px 8px;
            outline: none;

            font-size: 16px;
          }

          .left {
            padding-right: 16px;
            flex: 1;
          }

          .right {
            flex: 1;
            padding-left: 16px;
          }

          .react-datepicker-wrapper,
          .react-datepicker__input-container {
            width: 100%;
          }
          .react-datepicker {
            &,
            * {
              color: var(--appointment-background);
              border-radius: 0px !important;
              font-family: Rubik;

              &:active,
              &:focus {
                outline: none;
              }
            }

            border-color: #eaeaea;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

            .react-datepicker__triangle {
              display: none;
              visibility: hidden;
            }

            .react-datepicker__header {
              background-color: #f7f6f6;
              border-bottom-color: #eaeaea;
            }

            .react-datepicker__day--selected,
            .react-datepicker__day--keyboard-selected {
              color: #fff;
              background-color: var(--primary);
            }
            .react-datepicker__day--selected,
            .react-datepicker__day--disabled {
              color: var(--light-gray);
              // background-color: var(--super-light-gray);
            }

            .react-datepicker__time-list-item--disabled {
              color: var(--light-gray) !important;
            }

            .react-datepicker__time-list-item--selected {
              color: #fff;
              background-color: var(--primary) !important;
            }
          }
        }

        &.text-area {
          // flex: 1;
          // height: 96px;
          font-family: "Rubik";
          margin-bottom: 0px;
        }

        .input-title {
          margin-bottom: 8px;
          font-weight: 500;
          color: var(--darkGray);
        }

        > :last-child {
          margin-left: 4px;
          max-width: calc(100% - 4px);
        }

        .double-input-row {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .input {
            flex: 1;

            + .input {
              margin-top: 0px;
              margin-left: 16px;
            }
          }
        }
      }

      .message {
        margin: 0px 50px;
        color: var(--gray);
        opacity: 0.5;
      }

      .review-col-title {
        margin-bottom: 12px;
        font-size: 20px;
        font-weight: 500;
      }

      .value-container {
        width: 100%;
        margin-left: 8px;
        margin-bottom: 8px;
        display: flex;
        font-size: 18px;

        .value-title {
          flex: 1;
          color: var(--gray);
          opacity: 0.5;
          text-align: right;
          margin-right: 12px;
        }
        .value-values {
          flex: 2;
        }
      }
    }

    .long-running-message {
      margin-top: 16px;
    }
  }

  .bottom-buttons {
    flex-shrink: 0;
    height: 60px;
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-buttons {
      display: flex;

      > :not(:last-child) {
        margin-right: 20px;
      }
    }

    .modal-button {
      height: 40px;
      width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--main);
      color: #fff;
      outline: none;
      border: none;
      border-radius: 2px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      font-weight: 700;
      cursor: pointer;

      &:active {
        opacity: 0.9;
      }

      &.prev {
        margin-left: 24px;
        background-color: #fff;
        color: var(--main);
        border: 2px solid var(--main);
      }

      &.cancel {
        margin-left: 24px;
        background-color: #fff;
        color: var(--darkGray);
        border: 2px solid var(--darkGray);
      }
    }
  }
}
