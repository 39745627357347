.collapsing-section {
  margin: 12px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .header-row {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 8px 12px;
    border-radius: 4px;
    align-items: center;
    justify-content: space-between;
    background-color: var(--light-gray);

    &.visible {
      margin-bottom: 16px;
    }

    .collapsing-section-header {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 24px;
    }

    .actions-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .action {
        font-size: 20px;
        margin-right: 12px;
        color: var(--secondary-color);
        cursor: pointer;
      }
    }
  }
}
